import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let saved_token = ''
try {
  if (localStorage.token) {
    saved_token = localStorage.token
  }
} catch (e) {}

let is_super = 0
try {
  if (localStorage.is_super) {
    is_super = localStorage.is_super
  }
} catch (e) {}

export default new Vuex.Store({
  state: {
    options: [],
    activeIndex: '/user',
    userInfo: {},
    contractInfo: {},
    token: saved_token,
    is_super: is_super,
  },
  mutations: {
    add_token (state, token, is_super) {
      this.state.token = token;
      this.state.is_super = is_super;
      try {
        localStorage.token = token;
        localStorage.is_super = is_super;
      } catch (e) {}
    },
    logout_token (state) {
      this.state.token = '';
      this.state.is_super = '';
      try {
        localStorage.removeItem('token');
        localStorage.removeItem('is_super');
      } catch (e) {}
    },
    // 添加tabs
    add_tabs (state, data) {
      this.state.options.push(data);
    },
    // 删除tabs
    delete_tabs (state, route) {
      let index = 0;
      for (let option of state.options) {
        if (option.route === route) {
          break;
        }
        index++;
      }
      this.state.options.splice(index, 1);
    },
    // clear_tabs (state) {
    //   this.state.options = [];
    // },
    // 设置当前激活的tab
    set_active_index (state, index) {
      this.state.activeIndex = index;
    },
    // 设置详情信息
    save_detail_info (state, info) {
      this.state.userInfo = info;
    },
    save_contract_info (state, info) {
      this.state.contractInfo = info
    }
  },
  actions: {

  }
})
