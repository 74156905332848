import axios from 'axios'

const ERR_OK = 0;
const TOKEN_INVALID = 1;
const NOT_AUTH = 4;

axios.defaults.baseURL = 'https://api.nbjinfu.cn'
// axios.defaults.baseURL = 'http://nbjinfu.oa:7788'   
axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.token;

export function get(url) {
    return function (params) {
        return axios.get(url, {
            params
        }).then((res) => {
            const {errno, data} = res.data
            if (errno === ERR_OK) {
                if (data.msg) {
                    window.vm.$message.info(data.msg)
                }
                return data
            }
            if (errno === NOT_AUTH) {
                let show_msg = '没有权限';
                if (data.msg) {
                    show_msg = data.msg;
                }
                window.vm.$message.error(show_msg);
                return false
            }
        }).catch(() => {
            axios.get('/my_api/auth/check_token', {
                params: {token: localStorage.token}
            }).then((r) => {
                if (r.data.errno === TOKEN_INVALID) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('is_super')
                    window.vm.$router.push('/login')
                }
            })
        })
    }
}


export function post(url) {
    return function (data) {
        return axios.post(url, data).then((res) => {
            if (res.data.errno === NOT_AUTH) {
                window.vm.$message.error('没有权限');
                return false
            }
            return res.data.data
        }).catch(() => {

        })
    }
}

export function axios_get(url, params) {
    return axios.get(url, {params})
        .then((res) => {
            const {errno, data} = res.data;
            if (errno === NOT_AUTH) {
                let show_msg = '没有权限';
                if (data.msg) {
                    show_msg = data.msg;
                }
                window.vm.$message.error(show_msg);
                return false
            }
            if (errno === ERR_OK) {
                if (data.msg) {
                    window.vm.$message.info(data.msg)
                }
                return data
            }
        }).catch(() => {
            axios.get('/my_api/auth/check_token', {
                params: {token: localStorage.token}
            }).then((r) => {
                if (r.data.errno === TOKEN_INVALID) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('is_super')
                    window.vm.$router.push('/login')
                }
            })
        })
}

export function axios_post(url, data) {
    return axios.post(url, data)
        .then((res) => {
            const {errno, data} = res.data;
            if (errno === NOT_AUTH) {
                let show_msg = '没有权限';
                if (data.msg) {
                    show_msg = data.msg;
                }
                window.vm.$message.error(show_msg);
                return false
            }

            if (data.msg) {
                window.vm.$message.info(data.msg)
            }
            if (errno === ERR_OK) {
                return data
            }
        }).catch(() => {
            axios.get('/my_api/auth/check_token', {
                params: {token: localStorage.token}
            }).then((res) => {
                if (res.data.errno === TOKEN_INVALID) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('is_super')
                    window.vm.$router.push('/login')
                }
            })
        })
}