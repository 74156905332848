export default [
    {
        path: '/',
        name: 'index_page',
        component: () => import('@/views/home'),
    },
    {
        path: '/staff',
        name: 'staff',
        component: () => import('@/views/staff_contact'),
    },
    {
        path: '/business',
        component: () => import('@/views/business/Index'),
    },

    {
        path: '/work_order',
        component: () => import('@/views/work_order/Index'),
    },

    {
        path: '/contract_list',
        name: '合同列表',
        component: () => import('@/views/contract_list')
    },

    {
        path: '/my_contract',
        name: '我的合同',
        component: () => import('@/views/my_contract')
    },

    {
        path: '/contract/detail/:id',
        name: 'contract_detail',
        component: () => import('@/views/contract_detail')
    },

    {
        path: '/meeting',
        name: 'meeting',
        component: () => import('@/views/meeting'),
    },
    {
        path: '/task',
        name: '任务中心',
        component: () => import('@/views/task/ListAll'),
    },
    {
        path: '/add_task',
        name: 'task_add',
        component: () => import('@/views/task/AddOne'),
    },
    {
        path: '/edit_task/:task_id',
        name: 'task_edit',
        component: () => import('@/views/task/EditOne'),
    },

    {
        path: "/gedu",
        component: () => import('@/views/gedu/layout'),
        children: [
            {
                path: '',
                component: () => import('@/views/gedu/Main')
            },
            {
                path: 'company_detail/:id',
                component: () => import('@/views/gedu/CompanyDetail')
            }
        ]
    },
    {
        path: '/advisory',
        name: '常年咨询列表',
        component: () => import('@/views/advisory/list')
    },
    {
        path: '/advisory/detail/:id',
        name: 'advisory_detail',
        component: () => import('@/views/advisory/detail')
    },

    {
        path: '/accountant',
        component: () => import('@/views/accountant/Index')
    },
    {
        path: '/finDock',
        name: '财务对接',
        component: () => import('@/views/accountant/FinDock')
    },
    {
        path: '/mail_list',
        component: () => import('@/views/mail/Main'),
    },
    {
        path: '/fp_list',
        component: () => import('@/views/fapiao/Main'),
    },
    {
        path: '/jujian',
        component: () => import('@/views/jujian/Index'),
    },
    {
        path: '/seal',
        name: 'seal',
        component: () => import('@/views/seal/index'),
    },

    {
        path: '/payment',
        name: 'payment',
        component: () => import('@/views/payment/Index')
    },
    {
        path: '/admin/payment',
        name: 'payment_admin',
        component: () => import('@/views/payment/admin/ListAll')
    },

    {
        path: '/contract/low_price',
        name: 'low_price',
        component: () => import('@/views/contract_list/low_price')
    },

    {
        path: '/contract/list_thirdpart',
        name: 'list_thirdpart',
        component: () => import('@/views/contract_list/third_part')
    },

    // {
    //     path: '/contract/list_revise',
    //     name: 'list_revise',
    //     component: () => import('@/pages/contract/ReviseContract')
    // },

    // ------- 人事行政  ----------------

    {
        path: '/add_holiday',
        component: () => import('@/views/hr/add_holiday')
    },

    {
        path: '/entry_apply',
        name: '入职申请',
        component: () => import('@/views/hr/entry_apply')
    },
    {
        path: '/join_apply',
        name: '转正申请',
        component: () => import('@/views/hr/join_apply')
    },
    {
        path: '/change_wage',
        name: '调薪申请',
        component: () => import('@/views/hr/change_wage')
    },
    {
        path: '/leave_apply',
        name: '离职申请',
        component: () => import('@/views/hr/leave_apply')
    },
    {
        path: '/info_confirm',
        name: '人力核对',
        component: () => import('@/views/hr/info_confirm')
    },
    {
        path: '/wage_confirm',
        name: '工薪复核',
        component: () => import('@/views/hr/wage_confirm')
    },
    
    {
        path: '/list_trainee',
        component: () => import('@/views/hr/list_trainee')
    },
    {
        path: '/regular_staff',
        component: () => import('@/views/hr/regular_staff')
    },

    

    // ------- 人事行政 END  ----------------

    
    {
        path: '/userList',
        name: '人员管理',
        component: () => import('@/admin_views/1_user_list'),
    },
    {
        path: '/wage',
        name: '工资管理',
        component: () => import('@/admin_views/2_wage_list'),
    },
    {
        path: '/wage/detail/:user_id/:month_offset',
        name: 'wage_detail',
        component: () => import('@/views/wage'),
    },
    {
        path: '/wage/tax_duty/list_files',
        component: () => import('@/admin_views/2_wage_list/tax_duty_list_files'),
    },
    {
        path: '/wage/send_wage/list_files',
        component: () => import('@/admin_views/2_wage_list/send_wage_list_files'),
    },
    {
        path: '/bill',
        name: '银行对账',
        component: () => import('@/admin_views/3_bill_list'),
    },

    {
        path: '/monthly_fund',
        name: '月收款量',
        component: () => import('@/admin_views/4_monthly_fund'),
    },

    {
        path: '/bank_manage',
        component: () => import('@/admin_views/5_bank_manage'),
    },

    {
        path: '/my_invoice',
        component: () => import('@/views/invoice/main'),
        redirect: '/my_invoice/list_my',
        children: [{
            path: 'list_my',
            component: () => import("@/views/invoice/person/ListTable")
        }, {
            path: 'list_user',
            component: () => import("@/views/invoice/admin/ListUser")
        }, {
            path: 'user_invoice/:user_id',
            component: () => import("@/views/invoice/admin/UserInvoice")
        }, {
            path: 'baoxiao',
            component: () => import('@/views/invoice/admin/BxLimit')
        }, {
            path: 'list_all',
            component: () => import('@/views/invoice/admin/ListAll')
        }]
    },

    
    {
        path: '/super',
        name: '超级管理',
        component: () => import('@/super_views'),
        redirect: '/super/business',
        children: [
            {
                path: 'endpoint',
                component: () => import("@/super_views/endpoint")
            }, 
            {
                path: 'business',
                component: () => import("@/super_views/business")
            }, 
            {
                path: 'business/edit_detail/:id',
                component: () => import("@/super_views/business/EditDetail")
            }, 
            {
                path: 'department',
                component: () => import("@/super_views/department")
            },
            {
                path: 'price',
                component: () => import("@/super_views/price")
            },
            {
                path: 'stat',
                component: () => import("@/super_views/stat")
            },
            {
                path: 'main_chart',
                component: () => import("@/super_views/fund_charts")
            }
        ],
        hidden: true
    },

];