export default {
    install(Vue) {
        Vue.prototype.getFileSize = function (fileByte) {
            var fileSizeByte = fileByte;
            var fileSizeMsg = "";
            if (fileSizeByte < 1048576) fileSizeMsg = (fileSizeByte / 1024).toFixed(2) + "KB";
            else if (fileSizeByte == 1048576) fileSizeMsg = "1MB";
            else if (fileSizeByte > 1048576 && fileSizeByte < 1073741824) fileSizeMsg = (fileSizeByte / (1024 * 1024)).toFixed(2) + "MB";
            else if (fileSizeByte > 1048576 && fileSizeByte == 1073741824) fileSizeMsg = "1GB";
            else if (fileSizeByte > 1073741824 && fileSizeByte < 1099511627776) fileSizeMsg = (fileSizeByte / (1024 * 1024 * 1024)).toFixed(2) + "GB";
            else fileSizeMsg = "文件超过1TB";
            return fileSizeMsg;
        };
        Vue.prototype.resizeImage = function (originWidth, originHeight, maxWidth, maxHeight) {
            let targetWidth = originWidth,
                targetHeight = originHeight;
            if (originWidth > maxWidth || originHeight > maxHeight) {
                if (originWidth / originHeight > maxWidth / maxHeight) {
                    // 更宽，按照宽度限定尺寸
                    targetWidth = maxWidth;
                    targetHeight = Math.round(maxWidth * (originHeight / originWidth))
                } else {
                    targetHeight = maxHeight;
                    targetWidth = Math.round(maxHeight * (originWidth / originHeight))
                }
            }
            return [targetWidth, targetHeight]
        };
        Vue.prototype.formatChineseMoney = function (n) {
            if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n))
                return "请输入阿拉伯数字";
            var unit = "京亿万仟佰拾兆万仟佰拾亿仟佰拾万仟佰拾元角分",
                str = "";
            n += "00";
            var p = n.indexOf('.');
            if (p >= 0)
                n = n.substring(0, p) + n.substr(p + 1, 2);
            unit = unit.substr(unit.length - n.length);
            for (var i = 0; i < n.length; i++)
                str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
            return str.replace(/零(仟|佰|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(兆|万|亿|元)/g, "$1").replace(/(兆|亿)万/g, "$1").replace(/(京|兆)亿/g, "$1").replace(/(京)兆/g, "$1").replace(/(京|兆|亿|仟|佰|拾)(万?)(.)仟/g, "$1$2零$3仟").replace(/^元零?|零分/g, "").replace(/(元|角)$/g, "$1整");
        };
        Vue.prototype.moneyUnit = function (val) {
            if (!val) return null;
            let money_string = ''
            if (val/10000 >= 1) {
                money_string += Math.floor(val/10000).toLocaleString() + '万';
                let rest_val = val - Math.floor(val/10000)*10000;
                if (rest_val > 0) {
                    money_string += rest_val.toFixed(2)
                }
                return money_string + '元'
            } else {
                return val + '元'
            }
        }
    }
};