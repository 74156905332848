<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'app',
  data() {
      return {}
  },
  mounted() {

  },
  methods: {
      if_https() {
          const ishttps = 'https:' == document.location.protocol ? true : false;
          if (ishttps) {
              window.location.href = "http://www.nbjinfu.cn"
          }
      }
  },
  computed: {}
}
</script>

<style>

</style>
