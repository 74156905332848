import {axios_get, axios_post} from './helpers'

const Request = {
    Base: {
        month_list(data) {
            return axios_get('/my_api/base/month_list', data);
        },
        add_holiday(data) {
            return axios_post('/my_api/base/add_holiday', data);
        },
        list_holiday(data) {
            return axios_get('/my_api/base/list_holiday', data);
        },
        del_holiday(data) {
            return axios_get('/my_api/base/del_holiday', data);
        },
        list_bank(data) {
            return axios_get('/my_api/base/list_bank', data);
        },
        generate_bbs_url(data) {
            return axios_get('/my_api/base/generate_bbs_url', data);
        }
    },
    Bank: {
        list_public(data) {
            return axios_get('/my_api/bank/list_public', data);
        },
        update_one(data) {
            return axios_post('/my_api/bank/update_one', data);
        },
        create_one(data) {
            return axios_post('/my_api/bank/create_one', data);
        },
        info_for_contract(data) {
            return axios_get('/my_api/bank/info_for_contract', data);
        },
        switch_bank_status(data) {
            return axios_post('/my_api/bank/switch_bank_status', data);
        }
    },
    Fapiao: {
        create_one(data) {
            return axios_post('/my_api/fapiao/create_fp', data);
        },
        list_for_contract(data) {
            return axios_get('/my_api/fapiao/list_fp_for_contract', data);
        },
        del_one(data) {
            return axios_get('/my_api/fapiao/del_fp', data);
        },
        list_all(data) {
            return axios_get('/my_api/fapiao/list_all_fp', data);
        },
        get_fp_bank_list(data) {
            return axios_get('/my_api/fapiao/get_fp_bank_list', data);
        },
        update_fp_bank(data) {
            return axios_get('/my_api/fapiao/update_fp_bank', data);
        },
        update_fp_file(data) {
            return axios_post('/my_api/fapiao/update_fp_file', data);
        },
        get_bank_list(data) {
            return axios_get('/my_api/fapiao/get_bank_list', data);
        },
        confirm_fp(data) {
            return axios_get('/my_api/fapiao/confirm_fp', data);
        },
        cancel_fp(data) {
            return axios_get('/my_api/fapiao/cancel_fp', data);
        },
        download_selected_excel(data) {
            return axios_get('/my_api/fapiao/download_selected_excel', data);
        },
        get_stat(data) {
            return axios_get('/my_api/fapiao/get_stat', data);
        },
        update_fapiao_date(data) {
            return axios_post('/my_api/fapiao/update_fapiao_date', data);
        }
    },
    Finance: {
        create_one(data) {
            return axios_post('/my_api/finance/create_one', data);
        },
        edit_one(data) {
            return axios_post('/my_api/finance/edit_one', data);
        },
        list_all(data) {
            return axios_get('/my_api/finance/list_all', data);
        },
        del_one(data) {
            return axios_get('/my_api/finance/del_one', data);
        }
    },
    Mail: {
        create_one(data) {
            return axios_post('/my_api/mail/create_one', data);
        },
        edit_one(data) {
            return axios_post('/my_api/mail/edit_one', data);
        },
        update_one(data) {
            return axios_post('/my_api/mail/update_one', data);
        },
        list_for_contract(data) {
            return axios_get('/my_api/mail/list_for_contract', data);
        },
        list_all(data) {
            return axios_get('/my_api/mail/list_all', data);
        },
        send_sms(data) {
            return axios_get('/my_api/mail/send_sms', data);
        },
        del_one(data) {
            return axios_get('/my_api/mail/del_one', data);
        }
    },
    Company: {
        create_one(data) {
            return axios_post('/my_api/company/create_one', data);
        },
        list_for_admin(data) {
            return axios_get('/my_api/company/list_for_admin', data);
        },
        update_company_logo(data) {
            return axios_post('/my_api/company/update_company_logo', data);
        },
        make_valid(data) {
            return axios_get('/my_api/company/make_valid', data);
        },
        make_reject(data) {
            return axios_get('/my_api/company/make_reject', data);
        },
        list_for_home(data) {
            return axios_get('/my_api/company/list_for_home', data);
        },
        list_for_more(data) {
            return axios_get('/my_api/company/list_for_more', data);
        },
        edit_one(data) {
            return axios_post('/my_api/company/edit_one', data);
        }
    },
    Payment: {
        create_one(data) {
            return axios_post('/my_api/bill/create_one_payment', data);
        },
        edit_one(data) {
            return axios_post('/my_api/bill/edit_one_payment', data);
        },
        list_my_payment(data) {
            return axios_get('/my_api/bill/list_my_payment', data);
        },
        make_pass(data) {
            return axios_get('/my_api/bill/make_pass_payment', data);
        },
        make_review(data) {
            return axios_get('/my_api/bill/make_review_payment', data);
        },
        delete_one(data) {
            return axios_get('/my_api/bill/delete_one_payment', data);
        },
        list_all_payment(data) {
            return axios_get('/my_api/bill/list_all_payment', data);
        },
        unpassed_count(data) {
            return axios_get('/my_api/bill/unpassed_count', data);
        },
        upload_bank_image(data) {
            return axios_post('/my_api/bill/upload_bank_image', data);
        },
        update_bank(data) {
            return axios_post('/my_api/bill/update_payment_bank', data);
        }
    },
    Namelist: {
        get_random(data) {
            return axios_get('/my_api/namelist/get_random', data);
        },
        update_namelist(data) {
            return axios_post('/my_api/namelist/update_namelist', data);
        },
        friend_confirm(data) {
            return axios_post('/my_api/namelist/friend_confirm', data);
        },
        get_namelist(data) {
            return axios_get('/my_api/namelist/get_namelist', data);
        }
    },
    Auth: {
        login(data) {
            return axios_post('/my_api/auth/login', data);
        },
        wx_qrcode(data) {
            return axios_get('/my_api/auth/get_qr_config', data);
        }
    },
    File: {
        remove_one(data) {
            return axios_get('/my_api/file/remove_one', data);
        },
        qiniu_upload_token(data) {
            return axios_get('/my_api/file/qiniu_upload_token', data);
        },
        upload_upyun_image(data) {
            return axios_post('/my_api/file/upload_upyun_image', data);
        }
    },
    Price: {
        list(data) {
            return axios_get('/my_api/base/list_price', data);
        },
        add_file(data) {
            return axios_post('/my_api/base/price_add_file', data);
        },
        add(data) {
            return axios_post('/my_api/base/create_price', data);
        },
        update(data) {
            return axios_post('/my_api/base/update_price', data);
        },
        del(data) {
            return axios_get('/my_api/base/del_price', data);
        },
        recovery(data) {
            return axios_get('/my_api/base/recover_price', data);
        }
    },
    Task: {
        list(data) {
            return axios_get('/my_api/base/list_task', data);
        },
        edit(data) {
            return axios_post('/my_api/base/edit_task', data);
        },
        edit_one(data) {
            return axios_post('/my_api/base/edit_one_task', data);
        },
        del(data) {
            return axios_get('/my_api/base/del_task', data);
        },
        add(data) {
            return axios_post('/my_api/base/add_task', data);
        },
        get_one(data) {
            return axios_get('/my_api/base/get_one_task', data);
        },
        confirm_task(data) {
            return axios_get('/my_api/base/confirm_task', data);
        },
        task_list_users(data) {
            return axios_get('/my_api/base/task_list_users', data);
        },
        latest_task(data) {
            return axios_get('/my_api/base/latest_task', data);
        }
    },
    Video: {
        get_one(data) {
            return axios_get('/my_api/video/get_one', data);
        },
        list(data) {
            return axios_get('/my_api/video/list_all', data);
        },
        add_cover(data) {
            return axios_post('/my_api/video/update_cover', data);
        },
        add(data) {
            return axios_post('/my_api/video/create_one', data);
        },
        update(data) {
            return axios_post('/my_api/video/edit_one', data);
        },
        del(data) {
            return axios_get('/my_api/video/del_one', data);
        }
    },
    Go: {
        list_all(data) {
            return axios_get('/my_api/go_out/list_all', data);
        },
        create_one(data) {
            return axios_post('/my_api/go_out/create_one', data);
        },
        update_one(data) {
            return axios_post('/my_api/go_out/update_one', data);
        },
        make_confirm(data) {
            return axios_get('/my_api/go_out/make_confirm', data);
        },
        make_review(data) {
            return axios_get('/my_api/go_out/make_review', data);
        },
        make_del(data) {
            return axios_get('/my_api/go_out/make_del', data);
        }
    },
    Department: {
        list(data) {
            return axios_get('/my_api/base/list_department', data);
        },
        add(data) {
            return axios_post('/my_api/base/add_department', data);
        },
        update(data) {
            return axios_post('/my_api/base/edit_department', data);
        },
        get_simple(data) {
            return axios_get('/my_api/base/get_simple_departments', data);
        }
    },
    Endpoint: {
        list(data) {
            return axios_get('/my_api/base/list_endpoint_role', data);
        },
        list_user(data) {
            return axios_get('/my_api/base/list_endpoint_users', data);
        },
        add(data) {
            return axios_post('/my_api/base/add_endpoint_role', data);
        },
        change_user(data) {
            return axios_get('/my_api/base/change_endpoint_user', data);
        },
        update(data) {
            return axios_post('/my_api/base/edit_endpoint', data);
        },
    },
    Business: {
        list_all(data) {
            return axios_get('/my_api/business/list_all', data);
        },
        list_valid(data) {
            return axios_get('/my_api/business/list_valid', data);
        },
        list_more(data) {
            return axios_get('/my_api/business/list_more', data);
        },
        list_users(data) {
            return axios_get('/my_api/business/list_users', data);
        },
        list_flow(data) {
            return axios_get('/my_api/business/list_flow', data);
        },
        add(data) {
            return axios_post('/my_api/business/add', data);
        },
        update_one(data) {
            return axios_post('/my_api/business/update_one', data);
        },
        hide_one(data) {
            return axios_get('/my_api/business/hide_one', data);
        },
        change_user(data) {
            return axios_get('/my_api/business/change_user', data);
        },
        add_price(data) {
            return axios_post('/my_api/business/add_price', data);
        },
        edit_price(data) {
            return axios_post('/my_api/business/edit_price', data);
        },
        get_pirce_list(data) {
            return axios_get('/my_api/business/get_pirce_list', data);
        },
        remove_price(data) {
            return axios_get('/my_api/business/remove_price', data);
        },
        add_file(data) {
            return axios_post('/my_api/business/add_file', data);
        },
        get_file_list(data) {
            return axios_get('/my_api/business/get_file_list', data);
        },
        remove_file(data) {
            return axios_get('/my_api/business/remove_file', data);
        },
        edit_file(data) {
            return axios_post('/my_api/business/edit_file', data);
        },
        list_for_price(data) {
            return axios_get('/my_api/business/list_for_price', data);
        },
        banks_for_business(data) {
            return axios_get('/my_api/business/banks_for_business', data);
        },
        business_relate_bank(data) {
            return axios_get('/my_api/business/business_relate_bank', data);
        },
        bank_for_create_contract(data) {
            return axios_get('/my_api/business/bank_for_create_contract', data);
        },
        update_share_info(data) {
            return axios_post('/my_api/business/update_share_info', data);
        }
    },
    Wage: {
        get_month(data) {
            return axios_get('/my_api/wage/get_month', data);
        },
        update_one(data) {
            return axios_post('/my_api/wage/update_one', data);
        },
        update_base(data) {
            return axios_post('/my_api/wage/update_base', data);
        },
        cal_penalty(data) {
            return axios_get('/my_api/wage/cal_penalty', data);
        },
        cal_all(data) {
            return axios_get('/my_api/wage/cal_all', data);
        },
        cal_tax(data) {
            return axios_get('/my_api/wage/cal_pub_new_tax', data);
        },
        get_one(data) {
            return axios_get('/my_api/wage/get_one', data);
        },
        get_wage_contract_list(data) {
            return axios_get('/my_api/wage/get_wage_contract_list', data);
        },
        update_kaoqing_data(data) {
            return axios_get('/my_api/wage/update_kaoqing_data', data);
        },
        update_base_bonus(data) {
            return axios_get('/my_api/wage/update_base_bonus', data);
        },
        update_monthly_fund(data) {
            return axios_get('/my_api/wage/update_monthly_fund', data);
        },
        get_base_one(data) {
            return axios_get('/my_api/wage/get_base_one', data);
        },
        get_one_dikou(data) {
            return axios_get('/my_api/wage/get_one_dikou', data);
        },
        edit_one_dikou(data) {
            return axios_post('/my_api/wage/edit_one_dikou', data);
        },
        list_tax_files(data) {
            return axios_get('/my_api/wage/list_tax_files', data);
        },
        get_stat(data) {
            return axios_get('/my_api/wage/get_stat', data);
        },
        send_wage_to_email(data) {
            return axios_get('/my_api/wage/send_wage_to_email', data);
        },
        list_send_wage_files(data) {
            return axios_get('/my_api/wage/list_send_wage_files', data);
        }
    },
    Bill: {
        list_out_bill(data) {
            return axios_get('/my_api/bill/list_out_bill', data);
        },
        cal_monthly_fund(data) {
            return axios_get('/my_api/bill/cal_monthly_fund', data);
        },
        get_monthly_fund(data) {
            return axios_get('/my_api/bill/get_monthly_fund', data);
        },
        get_user_bill_list(data) {
            return axios_get('/my_api/bill/get_user_bill_list', data);
        },
        get_bill_cost(data) {
            return axios_get('/my_api/bill/get_bill_cost', data);
        },
        get_out_bills(data) {
            return axios_get('/my_api/bill/get_out_bills', data);
        },
        create_one(data) {
            return axios_post('/my_api/bill/create_one', data);
        },
        create_out_one(data) {
            return axios_post('/my_api/bill/create_out_one', data);
        },
        edit_one(data) {
            return axios_post('/my_api/bill/edit_one', data);
        },
        edit_out_one(data) {
            return axios_post('/my_api/bill/edit_out_one', data);
        },
        if_invoice(data) {
            return axios_get('/my_api/bill/if_invoice', data);
        },
        list_for_invoice(data) {
            return axios_get('/my_api/bill/list_for_invoice', data);
        },
        list_for_bank(data) {
            return axios_get('/my_api/bill/list_for_bank', data);
        },
        bank_list(data) {
            return axios_get('/my_api/bill/bank_list', data);
        },
        change_bank(data) {
            return axios_post('/my_api/bill/change_bank', data);
        },
        make_invalid(data) {
            return axios_get('/my_api/bill/make_invalid', data);
        },
        make_out_invalid(data) {
            return axios_get('/my_api/bill/make_out_invalid', data);
        },
        get_one_monthly_fund(data) {
            return axios_get('/my_api/bill/get_one_monthly_fund', data);
        },
        list_invoice_limit(data) {
            return axios_get('/my_api/bill/list_invoice_limit', data);
        },
        get_department_stat(data) {
            return axios_get('/my_api/bill/get_department_stat', data);
        }
    },
    Article: {
        crawl(data) {
            return axios_get('/my_api/article/make_crawl', data);
        },
        list_all(data) {
            return axios_get('/my_api/article/list_all', data);
        },
        del_one(data) {
            return axios_get('/my_api/article/del_one', data);
        },
        update_one(data) {
            return axios_post('/my_api/article/update_one', data);
        },
        upload_image(data) {
            return axios_post('/my_api/article/upload_image', data);
        },
        add_md(data) {
            return axios_post('/my_api/article/add_md', data);
        },
        list_my_md(data) {
            return axios_get('/my_api/article/list_my_md', data);
        },
        edit_md(data) {
            return axios_post('/my_api/article/edit_md', data);
        },
        get_one(data) {
            return axios_get('/my_api/article/get_one', data);
        },
    },
    SM_article: {
        add_one(data) {
            return axios_post('/my_api/article/add_sm_article', data);
        },
        list_all(data) {
            return axios_get('/my_api/article/list_sm_article', data);
        }
    },
    Contract: {
        list(data) {
            return axios_get('/my_api/contract/new_list_all', data);
        },
        list_my(data) {
            return axios_get('/my_api/contract/list_my', data);
        },
        ticheng_list(data) {
            return axios_get('/my_api/contract/ticheng_list', data);
        },
        base_data(data) {
            return axios_get('/my_api/contract/get_base_data', data);
        },
        base_list_data(data) {
            return axios_get('/my_api/contract/get_base_list_data', data);
        },
        list_comments(data) {
            return axios_get('/my_api/contract/list_comments', data);
        },
        add_comment(data) {
            return axios_post('/my_api/contract/add_comment', data);
        },
        del_comment(data) {
            return axios_get('/my_api/contract/del_comment', data);
        },
        list_partners(data) {
            return axios_get('/my_api/contract/list_partners', data);
        },
        add_partner(data) {
            return axios_post('/my_api/contract/add_partner', data);
        },
        create_one(data) {
            return axios_post('/my_api/contract/create_one', data);
        },
        add_jujian(data) {
            return axios_post('/my_api/contract/add_jujian', data);
        },
        update_one(data) {
            return axios_post('/my_api/contract/update_one', data);
        },
        check_edit(data) {
            return axios_get('/my_api/contract/check_edit', data);
        },
        make_complete(data) {
            return axios_get('/my_api/contract/make_complete', data);
        },
        make_invalid(data) {
            return axios_get('/my_api/contract/make_invalid', data);
        },
        make_hide(data) {
            return axios_get('/my_api/contract/make_hide', data);
        },
        update_cost(data) {
            return axios_post('/my_api/contract/update_cost', data);
        },
        list_flows(data) {
            return axios_get('/my_api/contract/list_flows', data);
        },
        list_files(data) {
            return axios_get('/my_api/contract/list_files', data);
        },
        confirm_flow(data) {
            return axios_get('/my_api/contract/confirm_flow', data);
        },
        customer_info(data) {
            return axios_get('/my_api/contract/customer_info', data);
        },
        add_file(data) {
            return axios_post('/my_api/contract/add_file', data);
        },
        delete_file(data) {
            return axios_get('/my_api/contract/delete_file', data);
        },
        add_final_name(data) {
            return axios_post('/my_api/contract/add_final_name', data);
        },
        list_for_fin_dock(data) {
            return axios_get('/my_api/contract/list_for_fin_dock', data);
        },
        add_fin_remind(data) {
            return axios_post('/my_api/contract/add_fin_remind', data);
        },
        list_hiden(data) {
            return axios_get('/my_api/contract/list_hiden', data);
        },
        list_dealer_ticheng(data) {
            return axios_get('/my_api/contract/list_dealer_ticheng', data);
        },
        del_dealer_ticheng(data) {
            return axios_get('/my_api/contract/del_dealer_ticheng', data);
        },
        list_dealer_ticheng_for_one(data) {
            return axios_get('/my_api/contract/list_dealer_ticheng_for_one', data);
        },
        reject_count(data) {
            return axios_get('/my_api/contract/reject_count', data);
        },
        thirdpart_pay_confirm(data) {
            return axios_get('/my_api/contract/thirdpart_pay_confirm', data);
        },
        get_flow_operator(data) {
            return axios_get('/my_api/contract/get_flow_operator', data);
        },
        create_flow(data) {
            return axios_post('/my_api/contract/create_flow', data);
        },
        update_flow(data) {
            return axios_post('/my_api/contract/update_flow', data);
        },
        change_flow_operator(data) {
            return axios_post('/my_api/contract/change_flow_operator', data);
        },
        get_bind_list(data) {
            return axios_get('/my_api/contract/get_bind_list', data);
        },
        create_bind(data) {
            return axios_post('/my_api/contract/create_bind', data);
        },
        make_unbind(data) {
            return axios_get('/my_api/contract/make_unbind', data);
        },
        query_contract(data) {
            return axios_get('/my_api/contract/query_contract', data);
        },
        relate_payments(data) {
            return axios_get('/my_api/contract/relate_payments', data);
        },
        flow_change_for_all(data) {
            return axios_get('/my_api/contract/flow_change_for_all', data);
        },
        update_kehu(data) {
            return axios_post('/my_api/contract/update_kehu', data);
        },
        update_bank(data) {
            return axios_get('/my_api/contract/update_bank', data);
        },
        del_child_contract(data) {
            return axios_get('/my_api/contract/del_child_contract', data);
        },
        get_base_cost(data) {
            return axios_get('/my_api/contract/get_base_cost', data);
        },
        flow_need_files(data) {
            return axios_get('/my_api/contract/flow_need_files', data);
        },
        add_flow_file_file(data) {
            return axios_post('/my_api/contract/add_flow_file_file', data);
        },
        fetch_flow_files(data) {
            return axios_get('/my_api/contract/fetch_flow_files', data);
        },
        do_del_flow_file(data) {
            return axios_get('/my_api/contract/do_del_flow_file', data);
        }
    },
    ThirdPart: {
        create_one_third(data) {
            return axios_post('/my_api/contract/create_one_third', data);
        },
        edit_one_third(data) {
            return axios_post('/my_api/contract/edit_one_third', data);
        },
        list_all(data) {
            return axios_get('/my_api/contract/list_all_thirdparts', data);
        },
        this_reject_thirdpart_count(data) {
            return axios_get('/my_api/contract/this_reject_thirdpart_count', data);
        },
        make_confirm(data) {
            return axios_get('/my_api/contract/make_confirm_thirdpart', data);
        },
        make_reject(data) {
            return axios_get('/my_api/contract/make_reject_thirdpart', data);
        },
        make_del(data) {
            return axios_get('/my_api/contract/make_del_thirdpart', data);
        },
        count_third_part(data) {
            return axios_get('/my_api/contract/count_third_part', data);
        },
        list_all_third_parts(data) {
            return axios_get('/my_api/contract/list_all_third_parts', data);
        }
    },
    ReviseContract: {
        submit_revise(data) {
            return axios_post('/my_api/contract/submit_revise', data);
        },
        revise_reject(data) {
            return axios_get('/my_api/contract/revise_reject', data);
        },
        fetch_revise(data) {
            return axios_get('/my_api/contract/fetch_revise', data);
        },
        confirm_revise(data) {
            return axios_get('/my_api/contract/confirm_revise', data);
        },
        confirm_review(data) {
            return axios_get('/my_api/contract/confirm_review', data);
        },
        list_all(data) {
            return axios_get('/my_api/contract/list_all_revise', data);
        },
        list_all_review(data) {
            return axios_get('/my_api/contract/list_all_review', data);
        },
        list_all_low_price(data) {
            return axios_get('/my_api/contract/list_all_low_price', data);
        },
        make_pass(data) {
            return axios_get('/my_api/contract/make_pass', data);
        },
        
        low_price_reject(data) {
            return axios_get('/my_api/contract/low_price_reject', data);
        },
        count_todo(data) {
            return axios_get('/my_api/contract/count_revise_contract', data);
        },
        count_low_price(data) {
            return axios_get('/my_api/contract/count_low_price', data);
        },
        add_low_price_reason(data) {
            return axios_post('/my_api/contract/add_low_price_reason', data);
        },
        // add_revise_text(data) {
        //     return axios_post('/my_api/contract/add_revise_text', data);
        // },
        // add_revise_file(data) {
        //     return axios_post('/my_api/contract/add_revise_file', data);
        // }
    },
    User: {
        wage_confirm_info(data) {
            return axios_get('/my_api/user/wage_confirm_info', data);
        },
        update_avatar(data) {
            return axios_post('/my_api/user/update_avatar', data);
        },
        update_qrcode(data) {
            return axios_post('/my_api/user/update_qrcode', data);
        },
        get_unionid_href(data) {
            return axios_get('/my_api/user/get_unionid_href', data);
        },
        list_contact(data) {
            return axios_get('/my_api/user/list_contact', data);
        },
        list_all(data) {
            return axios_get('/my_api/user/list_all', data);
        },
        list_wage_all(data) {
            return axios_get('/my_api/user/list_wage_all', data);
        },
        list_leave(data) {
            return axios_get('/my_api/user/list_leave', data);
        },
        get_leader(data) {
            return axios_get('/my_api/user/get_leader', data);
        },
        set_leader(data) {
            return axios_post('/my_api/user/set_leader', data);
        },
        set_valid(data) {
            return axios_post('/my_api/user/set_valid', data);
        },
        make_leave(data) {
            return axios_post('/my_api/user/make_leave', data);
        },
        signup(data) {
            return axios_post('/my_api/user/signup', data);
        },
        get_profile(data) {
            return axios_get('/my_api/user/my_profile', data);
        },
        update_pw(data) {
            return axios_post('/my_api/user/update_pw', data);
        },
        update_bank(data) {
            return axios_post('/my_api/user/update_bank', data);
        },
        update_base(data) {
            return axios_post('/my_api/user/update_base', data);
        },
        update_join(data) {
            return axios_post('/my_api/user/update_join', data);
        },
        list_my_invite(data) {
            return axios_get('/my_api/user/list_my_invite', data);
        },
        change_invite(data) {
            return axios_get('/my_api/user/change_invite', data);
        },
        update_lock(data) {
            return axios_post('/my_api/user/update_lock', data);
        },
        q_count(data) {
            return axios_get('/my_api/user/q_count', data);
        },
        list_entry(data) {
            return axios_get('/my_api/user/list_entry', data);
        },
        update_entry(data) {
            return axios_post('/my_api/user/update_entry', data);
        },
        my_join_info(data) {
            return axios_get('/my_api/user/my_join_info', data);
        },
        add_join_apply(data) {
            return axios_post('/my_api/user/add_join_apply', data);
        },
        list_join_apply(data) {
            return axios_get('/my_api/user/list_join_apply', data);
        },
        update_base_wage(data) {
            return axios_post('/my_api/user/update_base_wage', data);
        },
        confirm_join_apply(data) {
            return axios_get('/my_api/user/confirm_join_apply', data);
        },
        reject_join_apply(data) {
            return axios_get('/my_api/user/reject_join_apply', data);
        },
        list_change_wage_apply(data) {
            return axios_get('/my_api/user/list_change_wage_apply', data);
        },
        add_change_wage_apply(data) {
            return axios_post('/my_api/user/add_change_wage_apply', data);
        },
        confirm_change_wage_apply(data) {
            return axios_get('/my_api/user/confirm_change_wage_apply', data);
        },
        reject_change_wage_apply(data) {
            return axios_get('/my_api/user/reject_change_wage_apply', data);
        },
        list_leave_apply(data) {
            return axios_get('/my_api/user/list_leave_apply', data);
        },
        add_leave_apply(data) {
            return axios_post('/my_api/user/add_leave_apply', data);
        },
        confirm_leave_apply(data) {
            return axios_get('/my_api/user/confirm_leave_apply', data);
        },
        delete_leave_apply(data) {
            return axios_get('/my_api/user/delete_leave_apply', data);
        },
        list_operator(data) {
            return axios_get('/my_api/user/list_operator', data);
        },
        list_trainee(data) {
            return axios_get('/my_api/user/list_trainee', data);
        },
        list_regular(data) {
            return axios_get('/my_api/user/list_regular', data);
        },
        hr_info_check(data) {
            return axios_get('/my_api/user/hr_info_check', data);
        },
        update_base_info(data) {
            return axios_post('/my_api/user/update_base_info', data);
        },
        change_leave_date(data) {
            return axios_post('/my_api/user/change_leave_date', data);
        },
        update_wage_apply(data) {
            return axios_post('/my_api/user/update_wage_apply', data);
        },
        list_company_belong_list(data) {
            return axios_get('/my_api/user/list_company_belong_list', data);
        },
        list_exam_user(data) {
            return axios_get('/my_api/user/list_exam_user', data);
        },
        make_exam(data) {
            return axios_post('/my_api/user/make_exam', data);
        },
        update_exam_date(data) {
            return axios_post('/my_api/user/update_exam_date', data);
        }
    },
    Score: {
        get_by_month(data) {
            return axios_get('/my_api/score/get_by_month', data);
        }
    },
    Gedu: {
        create_gd_zone(data) {
            return axios_post('/my_api/gedu/create_gd_zone', data);
        },
        edit_gd_zone(data) {
            return axios_post('/my_api/gedu/edit_gd_zone', data);
        },
        list_solo_company_contract(data) {
            return axios_get('/my_api/gedu/list_solo_company_contract', data);
        },
        list_gd_zone(data) {
            return axios_get('/my_api/gedu/list_gd_zone', data);
        },
        list_gd_company(data) {
            return axios_get('/my_api/gedu/list_gd_company', data);
        },
        create_gd_company(data) {
            return axios_post('/my_api/gedu/create_gd_company', data);
        },
        update_gd_company_license(data) {
            return axios_post('/my_api/gedu/update_gd_company_license', data);
        },
        get_gd_company(data) {
            return axios_get('/my_api/gedu/get_gd_company', data);
        },
        get_gd_invoice(data) {
            return axios_get('/my_api/gedu/get_gd_invoice', data);
        },
        list_invoice(data) {
            return axios_get('/my_api/gedu/list_invoice', data);
        },
        review_invoice(data) {
            return axios_get('/my_api/gedu/review_invoice', data);
        },
        edit_gd_company(data) {
            return axios_post('/my_api/gedu/edit_gd_company', data);
        },
        create_gd_invoice(data) {
            return axios_post('/my_api/gedu/create_gd_invoice', data);
        },
        update_gd_invoice_scan(data) {
            return axios_post('/my_api/gedu/update_gd_invoice_scan', data);
        },
        update_gd_invoice_mail_scan(data) {
            return axios_post('/my_api/gedu/update_gd_invoice_mail_scan', data);
        },
        edit_gd_invoice(data) {
            return axios_post('/my_api/gedu/edit_gd_invoice', data);
        },
        del_gd_invoice(data) {
            return axios_get('/my_api/gedu/del_gd_invoice', data);
        },
        invoice_add_file(data) {
            return axios_post('/my_api/gedu/invoice_add_file', data);
        },
        remove_invoice_file(data) {
            return axios_get('/my_api/gedu/remove_invoice_file', data);
        },
        list_final_name(data) {
            return axios_get('/my_api/gedu/list_final_name', data);
        },
        update_withdraw(data) {
            return axios_post('/my_api/gedu/update_withdraw', data);
        },
        get_relative_contract(data) {
            return axios_get('/my_api/gedu/get_relative_contract', data);
        },
        list_gd_account(data) {
            return axios_get('/my_api/gedu/list_gd_account', data);
        },
        list_gd_cancel(data) {
            return axios_get('/my_api/gedu/list_gd_cancel', data);
        },
        cancel_gd_company(data) {
            return axios_get('/my_api/gedu/cancel_gd_company', data);
        },
        list_gd_scale(data) {
            return axios_get('/my_api/gedu/list_gd_scale', data);
        }
    },
    Meeting: {
        list_all(data) {
            return axios_get('/my_api/meeting/list_all', data);
        },
        create_one(data) {
            return axios_post('/my_api/meeting/create_one', data);
        },
        delete_one(data) {
            return axios_get('/my_api/meeting/delete_one', data);
        },
        update_one(data) {
            return axios_post('/my_api/meeting/update_one', data);
        },
        upload_file(data) {
            return axios_post('/my_api/meeting/upload_file', data);
        },
        confirm_meeting(data) {
            return axios_get('/my_api/meeting/confirm_meeting', data);
        },
        meeting_list_users(data) {
            return axios_get('/my_api/meeting/meeting_list_users', data);
        },
        latest_meeting(data) {
            return axios_get('/my_api/meeting/latest_meeting', data);
        },
        file_delete(data) {
            return axios_post('/my_api/meeting/file_delete', data);
        }
    },
    Seal: {
        list_all(data) {
            return axios_get('/my_api/seal/list_all', data);
        },
        create_one(data) {
            return axios_post('/my_api/seal/create_one', data);
        },
        delete_one(data) {
            return axios_get('/my_api/seal/delete_one', data);
        },
        update_one(data) {
            return axios_post('/my_api/seal/update_one', data);
        },
        apply_borrow(data) {
            return axios_post('/my_api/seal/apply_borrow', data);
        },
        confirm_borrow(data) {
            return axios_get('/my_api/seal/confirm_borrow', data);
        },
        confirm_return(data) {
            return axios_get('/my_api/seal/confirm_return', data);
        },
        remain_elements(data) {
            return axios_get('/my_api/seal/remain_elements', data);
        },
        list_apply(data) {
            return axios_get('/my_api/seal/list_apply', data);
        },
        valid_one(data) {
            return axios_get('/my_api/seal/valid_one', data);
        },
        reject_one(data) {
            return axios_get('/my_api/seal/reject_one', data);
        },
        untreated_count(data) {
            return axios_get('/my_api/seal/untreated_count', data);
        },
    },
    Visit: {
        list_all(data) {
            return axios_get('/my_api/visit/list_all', data);
        },
        create_one(data) {
            return axios_post('/my_api/visit/create_one', data);
        },
        update_one(data) {
            return axios_post('/my_api/visit/update_one', data);
        },
        make_valid(data) {
            return axios_get('/my_api/visit/make_valid', data);
        },
        delete_one(data) {
            return axios_get('/my_api/visit/delete_one', data);
        },
        upload_image(data) {
            return axios_post('/my_api/visit/upload_image', data);
        }
    },
    Stat: {
        get_monthly_bill_sum(data) {
            return axios_get('/my_api/stat/get_monthly_bill_sum', data);
        },
        get_monthly_business_type(data) {
            return axios_get('/my_api/stat/get_monthly_business_type', data);
        },
        get_monthly_business_fund(data) {
            return axios_get('/my_api/stat/get_monthly_business_fund', data);
        },
        fetch_company_monthly_profit(data) {
            return axios_get('/my_api/stat/fetch_company_monthly_profit', data);
        }
    },
    Advisory: {
        list_all(data) {
            return axios_get('/my_api/advisory/list_all', data);
        },
        edit_one(data) {
            return axios_post('/my_api/advisory/edit_one', data);
        },
        create_one(data) {
            return axios_post('/my_api/advisory/create_one', data);
        },
        get_one(data) {
            return axios_get('/my_api/advisory/get_one', data);
        },
        get_detail(data) {
            return axios_get('/my_api/advisory/get_detail', data);
        },
        make_del(data) {
            return axios_get('/my_api/advisory/make_del', data);
        },
        make_valid(data) {
            return axios_get('/my_api/advisory/make_valid', data);
        },
        cal_fee(data) {
            return axios_get('/my_api/advisory/cal_fee', data);
        },
        add_operator(data) {
            return axios_post('/my_api/advisory/add_operator', data);
        },
        del_operator(data) {
            return axios_get('/my_api/advisory/del_operator', data);
        },
        add_new_operator(data) {
            return axios_post('/my_api/advisory/add_new_operator', data);
        }
    },
    Invoice: {
        get_my_invoice_limit(data) {
            return axios_get('/my_api/invoice/get_my_invoice_limit', data);
        },
        create_one(data) {
            return axios_post('/my_api/invoice/create_one', data);
        },
        edit_one(data) {
            return axios_post('/my_api/invoice/edit_one', data);
        },
        make_del(data) {
            return axios_get('/my_api/invoice/make_del', data);
        },
        list_head(data) {
            return axios_get('/my_api/invoice/list_head', data);
        },
        my_list(data) {
            return axios_get('/my_api/invoice/my_list', data);
        },
        get_list(data) {
            return axios_get('/my_api/invoice/get_list', data);
        },
        make_valid(data) {
            return axios_get('/my_api/invoice/make_valid', data);
        },
        make_type_edit(data) {
            return axios_get('/my_api/invoice/make_type_edit', data);
        },
        list_users(data) {
            return axios_get('/my_api/invoice/list_users', data);
        },
        list_all(data) {
            return axios_get('/my_api/invoice/list_all', data);
        }
    },
    Work_order: {
        create_one(data) {
            return axios_post('/my_api/work_order/create_one', data);
        },
        untreated_count(data) {
            return axios_get('/my_api/work_order/untreated_count', data);
        },
        list_untreated(data) {
            return axios_get('/my_api/work_order/list_untreated', data);
        },
        make_treated(data) {
            return axios_post('/my_api/work_order/make_treated', data);
        },
        remind_complete(data) {
            return axios_post('/my_api/work_order/remind_complete', data);
        },
        list_all(data) {
            return axios_get('/my_api/work_order/list_all', data);
        },
        make_reply(data) {
            return axios_post('/my_api/work_order/make_reply', data);
        }
    },
    Cost_order: {
        create_one(data) {
            return axios_post('/my_api/cost_order/create_one', data);
        },
        untreated_count(data) {
            return axios_get('/my_api/cost_order/untreated_count', data);
        },
        list_untreated(data) {
            return axios_get('/my_api/cost_order/list_untreated', data);
        },
        list_for_contract(data) {
            return axios_get('/my_api/cost_order/list_for_contract', data);
        },
        make_del(data) {
            return axios_post('/my_api/cost_order/make_del', data);
        }
    },
    Accountant: {
        list_all(data) {
            return axios_get('/my_api/accountant/list_all', data);
        },
        get_user_list(data) {
            return axios_get('/my_api/accountant/get_user_list', data);
        },
        update_one(data) {
            return axios_post('/my_api/accountant/update_one', data);
        },
        get_stat(data) {
            return axios_get('/my_api/accountant/get_stat', data);
        },
        update_price(data) {
            return axios_get('/my_api/accountant/update_price', data);
        },
        update_low_volume(data) {
            return axios_get('/my_api/accountant/update_low_volume', data);
        },
        create_renew_contract(data) {
            return axios_post('/my_api/accountant/create_renew_contract', data);
        }
    },
    Jujian: {
        get_one(data) {
            return axios_get('/my_api/jujian/get_one', data);
        },
        get_list(data) {
            return axios_get('/my_api/jujian/get_list', data);
        },
        confirm_step(data) {
            return axios_get('/my_api/jujian/confirm_step', data);
        },
        reapply(data) {
            return axios_post('/my_api/jujian/reapply', data);
        },
        upload_file(data) {
            return axios_post('/my_api/jujian/upload_file', data);
        },
        delete_file(data) {
            return axios_get('/my_api/jujian/delete_file', data);
        }
    }
};

export default Request;
