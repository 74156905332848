import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import base from './tools/base'

import ElementUI from 'element-ui'
import Vuelidate from 'vuelidate'
import VueCroppie from 'vue-croppie'
import mavonEditor from 'mavon-editor'
import ImageUploader from 'vue-image-upload-resize'

import Request from "./api/request";
Vue.prototype.$r = Request;

Vue.config.productionTip = false;

Vue.use(ElementUI);
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Vuelidate);
Vue.use(VueCroppie);
Vue.use(mavonEditor);
Vue.use(base);
Vue.use(ImageUploader);

import * as echarts from 'echarts'
import VueEcharts from 'vue-echarts'
Vue.component('v-chart', VueEcharts)

import 'mavon-editor/dist/css/index.css'
import '../theme/button.css'
import '../theme/card.css'
import './assets/gridtable.css'
import './assets/my.css'


let vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.vm = vm;
window.token = localStorage.getItem('token');
