import Vue from 'vue'
import VueRouter from 'vue-router'
import viewsRouter from './views_router'

Vue.use(VueRouter)

let router = new VueRouter({
  routes: [
      {
          path: '/login',
          name: '登录',
          component: () => import('../views/Login'),
      },
      {
          path: '/signup',
          name: '注册',
          component: () => import('@/views/Signup'),
      },
      {
          path: "/",
          name: "Home",
          component: () => import('@/components/layout/app-frame'),
          children: [
              ...viewsRouter
          ]
      }
  ]
});

const openRoutes = ['/login', '/signup'];

router.beforeEach((to, from, next) => {
    if (openRoutes.includes(to.path)) {
        next()
    } else if (localStorage.token) {
        next()
    } else {
        next('/login')
    }
});

export default router
